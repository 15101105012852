import classNames from "classnames";
import {observer} from "mobx-react";

import styles from './styles/GalleryBlock.module.scss';
import BlockTitle from "../_BlockTitle";
import ImageGalleryItem from "./ImageGalleryItem";
import VideoGalleryItem from "./VideoGalleryItem";
import {CSSTransition} from "react-transition-group";
import React, {useContext} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {ITerraceProps} from "../Terrace";
import ViewMoreButton from "../ViewMoreButton";
import {IReactiveMediaContentItem, ReactiveContentItem} from "../../../../stores/SchoolFeedStore";
import {SupportedContentItemTypes} from "../../../schoolfeed/PostManager/SchoolFeedPostManager";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";
import {useCreateContentItem} from "../../../../hooks/useCreateContentItem";

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

interface GalleryBlockProps extends ITerraceProps {
    blockObj: Omit<IGalleryTerrace, "content_items"> & {content_items: IReactiveMediaContentItem[]},
}

const GalleryBlock = observer((props: GalleryBlockProps) => {
    const {interfaceStore, sidebarStore} = useContext(StoreContext);

    const {handleSave} = useCreateContentItem()

    const sliderWrapperClassName = classNames({
        [styles.sliderWrapper]: true,
    });

    const content = props.blockObj.content_items.slice(0, interfaceStore.breakpoint === "break-point-xs" ? 4: 8);

    function getItemAtIndex(idx) {
        return content[idx] as ReactiveContentItem || null;
    }

    const onClick = () => {
        sidebarStore.setSidebar(
            {
                view: "SchoolFeedPost",
                sbtype: SupportedContentItemTypes.MEDIA,
                handleSave: handleSave,
            }
        )
    }

    const galleryBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Image',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClick,
        },
    ]

    return (
        <section className={sliderWrapperClassName}>
            <BlockTitle blockObj={props.blockObj}
                        aboveTheFold={props.aboveTheFold}
                        dropDownItems={galleryBlockDropDownItems}
                        showViewMore={false}
                        />
            <div className={styles.contentContainer}>
                {content.map((item, idx) => {
                    if (item.type.includes("photo")) {
                        return <CSSTransition appear key={item.id} classNames={transitionClassNames} timeout={500} in={props.aboveTheFold}>
                            <ImageGalleryItem
                                handleDelete={props.handleDeleteContentItem}
                                item={item}
                                getItemAtIndex={getItemAtIndex}
                                totalItemCount={content.length}
                                initialItemIndex={idx}
                            />
                        </CSSTransition>
                    } else {
                        return <CSSTransition appear key={item.id} classNames={transitionClassNames} timeout={500} in={props.aboveTheFold}>
                            <VideoGalleryItem
                                handleDelete={props.handleDeleteContentItem}
                                item={item}
                                getItemAtIndex={getItemAtIndex}
                                totalItemCount={content.length}
                                initialItemIndex={idx}
                            />
                        </CSSTransition>
                    }
                })}
            </div>
            <div>
                <div className={styles.viewMoreButton}>
                    {props.blockObj.is_more &&  <ViewMoreButton terraceType={props.blockObj.type}  />}
                </div>
            </div>
        </section>
    );
});

export default GalleryBlock;
